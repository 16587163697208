import { ResidentInterface } from "src/app/shared/interfaces/residentInterface";
import { Injectable } from "@angular/core";

@Injectable()
export class RelativeService {
  private relative: ResidentInterface = {};

  constructor() {}

  changeRelative(relative: ResidentInterface) {
    this.relative = relative;
  }

  getRelative(): ResidentInterface {
    return this.relative;
  }
}

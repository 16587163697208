import { API } from "../../global/api";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor(private http: HttpClient) {}
  public getAllResidences(): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        page: "0",
        size: "10000",
        eagerload: "false",
      },
    });
    return this.http.get(API.GET_ALL_RESIDENCES + "?" + params);
  }
  public getResidence(id: string): Observable<any> {
    return this.http.get(API.GET_RESIDENCE.replace("{id}", id));
  }

  public getAccount(): Observable<any> {
    return this.http.get(API.GET_ACCOUNT);
  }
}

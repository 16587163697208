import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class MobilePlatformGuard implements CanActivate {
  constructor(public platform: Platform) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.platform.ready().then(() => {
      return !this.platform.is("desktop");
    });
  }
}

import { AuthGuard } from "./guard/auth.guard";
import { MobilePlatformGuard } from "./guard/mobile-platform.guard";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { RedirectGuard } from "./guard/redirect.guard";
import { ClickOutsideModule } from "ng-click-outside";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { IonicModule } from "@ionic/angular";
import { AuthorizationInterceptor } from "./interceptors/authorization.interceptor";
import { AuthService } from "../shared/services/auth.service";
import { LoadingComponent } from "./components/loading/loading.component";

@NgModule({
  declarations: [LoadingComponent, HeaderComponent, FooterComponent],
  imports: [CommonModule, HttpClientModule, ClickOutsideModule, IonicModule],
  exports: [FooterComponent, HeaderComponent, LoadingComponent],
  providers: [
    AuthGuard,
    MobilePlatformGuard,
    RedirectGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}

import { AuthGuard } from "./core/guard/auth.guard";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MobilePlatformGuard } from "./core/guard/mobile-platform.guard";
import { RedirectGuard } from "./core/guard/redirect.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/welcome-app",
    pathMatch: "full",
  },
  {
    path: "welcome-app",
    loadChildren: () =>
      import("./pages/welcome/welcome.module").then((m) => m.WelcomePageModule),
    data: {
      title: "v-health: Bienvenido",
    },
    canActivate: [RedirectGuard, MobilePlatformGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/loginWeb/login-web.module").then(
        (m) => m.LoginWebPageModule
      ),
    data: {
      title: "v-health: Login",
    },
  },
  {
    path: "politica",
    loadChildren: () =>
      import("./pages/policyWeb/policy.module").then((m) => m.PolicyModule),
    data: {
      title: "v-health: Política",
    },
  },
  {
    path: "registro",
    loadChildren: () =>
      import("./pages/registerWeb/register-web.module").then(
        (m) => m.RegisterWebPageModule
      ),
    data: {
      title: "v-health: Registro",
    },
  },
  {
    path: "perfil",
    loadChildren: () =>
      import("./pages/profileWeb/profile-web.module").then(
        (m) => m.ProfileWebPageModule
      ),
    data: {
      title: "v-health: Perfil",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "diario",
    loadChildren: () =>
      import("./pages/diaryWeb/diary-web.module").then(
        (m) => m.DiaryWebPageModule
      ),
    data: {
      title: "v-health: Diario",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "diario/comparar/:residentId/:sessionId/:type",
    loadChildren: () =>
      import("./pages/diary-compare-web/diary-compare-web.module").then(
        (m) => m.DiaryCompareWebPageModule
      ),
    data: {
      title: "v-health: Diario",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "planif",
    loadChildren: () =>
      import("./pages/planifWeb/planif-web.module").then(
        (m) => m.PlanifWebPageModule
      ),
    data: {
      title: "v-health: Protocolo",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "planif/comparar/:residentId/:sessionId/:type",
    loadChildren: () =>
      import("./pages/planif-compare-web/planif-compare-web.module").then(
        (m) => m.PlanifCompareWebPageModule
      ),
    data: {
      title: "v-health: Protocolo",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "directo",
    loadChildren: () =>
      import("./pages/directWeb/direct-web.module").then(
        (m) => m.DirectWebPageModule
      ),
    data: {
      title: "v-health: Directo",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "avisos",
    loadChildren: () =>
      import("./pages/noticeWeb/notice-web.module").then(
        (m) => m.NoticeWebModule
      ),
    data: {
      title: "v-health: Avisos",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "invitaciones",
    loadChildren: () =>
      import("./pages/invitationWeb/invitation-web.module").then(
        (m) => m.InvitationWebPageModule
      ),
    data: {
      title: "v-health: Invitaciones",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "dispositivos",
    loadChildren: () =>
      import("./pages/deviceWeb/device-web.module").then(
        (m) => m.DeviceWebPageModule
      ),
    data: {
      title: "v-health: Dispositivos",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "monitores",
    loadChildren: () =>
      import("./pages/bleDeviceWeb/bleDevice-web.module").then(
        (m) => m.BleDeviceWebPageModule
      ),
    data: {
      title: "v-health: Monitores",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "centros",
    loadChildren: () =>
      import("./pages/centerWeb/center-web.module").then(
        (m) => m.CenterWebPageModule
      ),
    data: {
      title: "v-health: Centros v-health",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "usuarios",
    loadChildren: () =>
      import("./pages/userWeb/user-web.module").then(
        (m) => m.UserWebPageModule
      ),
    data: {
      title: "v-health: Usuarios v-health",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "sesiones",
    loadChildren: () =>
      import("./pages/sessionWeb/session-web.module").then(
        (m) => m.SessionWebPageModule
      ),
    data: {
      title: "v-health: Sesiones v-health",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "login-app",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
    data: {
      title: "v-health: Login",
    },
    canActivate: [MobilePlatformGuard],
  },
  {
    path: "registro-app",
    loadChildren: () =>
      import("./pages/register/register.module").then(
        (m) => m.RegisterPageModule
      ),
    data: {
      title: "v-health: Registro",
    },
    canActivate: [MobilePlatformGuard],
  },
  {
    path: "notificaciones-app",
    loadChildren: () =>
      import("./pages/notifications/notifications.module").then(
        (m) => m.NotificationsPageModule
      ),
    data: {
      title: "v-health: Notificaciones",
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
  },
  {
    path: "diario-app",
    loadChildren: () =>
      import("./pages/diary/diary.module").then((m) => m.DiaryPageModule),
    data: {
      title: "v-health: Diario",
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "perfil-app",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
    data: {
      title: "v-health: Perfil",
    },
    canActivate: [MobilePlatformGuard],
  },
  {
    path: "terminos-app",
    loadChildren: () =>
      import("./pages/terms/terms.module").then((m) => m.TermsPageModule),
    data: {
      title: "v-health: Terminos y condiciones",
    },
    canActivate: [MobilePlatformGuard],
  },
  {
    path: "privacidad-app",
    loadChildren: () =>
      import("./pages/privacy/privacy.module").then((m) => m.PrivacyPageModule),
    data: {
      title: "v-health: Politica de privacidad",
    },
    canActivate: [MobilePlatformGuard],
  },
  {
    path: "avisos-app",
    loadChildren: () =>
      import("./pages/alerts/alerts.module").then((m) => m.AlertsPageModule),
    data: {
      title: "v-health: Avisos",
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
  },
  {
    path: "directo-app",
    loadChildren: () =>
      import("./pages/live/live.module").then((m) => m.LivePageModule),
    data: {
      title: "v-health: Directo",
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
  },
  {
    path: "bleDevices-app",
    loadChildren: () =>
      import("./pages/bleDevices/bleDevices.module").then(
        (m) => m.BleDevicesPageModule
      ),
    data: {
      title: "v-health: Dispositivos",
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
  },
  {
    path: "configuracion-app",
    loadChildren: () =>
      import("./pages/settings/settings.module").then(
        (m) => m.SettingsPageModule
      ),
    data: {
      title: "v-health: Configuracion",
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
  },
  {
    path: "contacto-app",
    loadChildren: () =>
      import("./pages/contact/contact.module").then((m) => m.ContactPageModule),
    data: {
      title: "v-health: Contacto",
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
  },
  {
    path: "perfil-monitorizado-app",
    loadChildren: () =>
      import("./pages/resident-profile/resident-profile.module").then(
        (m) => m.ResidentProfilePageModule
      ),
    data: {
      title: "v-health: Perfil Monitorizado",
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
  },
  {
    path: "eventos-app",
    loadChildren: () =>
      import("./pages/events/events.module").then((m) => m.EventsPageModule),
    data: {
      title: "v-health: Eventos",
    },
    canActivate: [MobilePlatformGuard, AuthGuard],
  },
  {
    path: "refresh",
    loadChildren: () =>
      import("./pages/refresh/refresh.module").then((m) => m.RefreshPageModule),
  },
  {
    path: "diary-compare-web",
    loadChildren: () =>
      import("./pages/diary-compare-web/diary-compare-web.module").then(
        (m) => m.DiaryCompareWebPageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { RelativeService } from "./shared/services/relative.service";
import { FiltersService } from "./shared/services/filters.service";
import { IdService } from "./shared/services/id.service";
import { CoreModule } from "./core/core.module";
import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { NgxPageScrollCoreModule } from "ngx-page-scroll-core";

import { NgxChartsModule } from "@swimlane/ngx-charts";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { RouteReuseStrategy } from "@angular/router";
import { AuthorizationInterceptor } from "./core/interceptors/authorization.interceptor";
import { PreviousRouteService } from "./shared/services/previous-route.service";
import { HttpCancelService } from "./shared/services/http-cancel-service";
import { ManageHttpInterceptor } from "./shared/interceptor/manage-http.interceptor";

import { Network } from "@ionic-native/network/ngx";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    NgxPageScrollCoreModule,
  ],
  providers: [
    Title,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    IdService,
    FiltersService,
    RelativeService,
    ScreenOrientation,
    PreviousRouteService,
    HttpCancelService,
    Network,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
